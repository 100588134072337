// Generated by Framer (44d7d2d)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const enabledGestures = {blPTmyqqg: {hover: true}, GgjuZoKLV: {hover: true}};

const cycleOrder = ["blPTmyqqg", "GgjuZoKLV"];

const serializationHash = "framer-OvI9X"

const variantClassNames = {blPTmyqqg: "framer-v-hpqhys", GgjuZoKLV: "framer-v-13q4vel"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "blPTmyqqg", light: "GgjuZoKLV"}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, Ga4XD6inS: icon ?? props.Ga4XD6inS ?? "Twitter", n98VOgYIP: link ?? props.n98VOgYIP, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "blPTmyqqg"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ga4XD6inS, n98VOgYIP, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "blPTmyqqg", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={n98VOgYIP} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-hpqhys", className, classNames)} framer-wiff9z`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"blPTmyqqg"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"blPTmyqqg-hover": {"data-framer-name": undefined}, "GgjuZoKLV-hover": {"data-framer-name": undefined}, GgjuZoKLV: {"data-framer-name": "light"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-o0pp9g-container"} layoutDependency={layoutDependency} layoutId={"ALlAvyiu4-container"}><Material color={"rgb(14, 14, 14)"} height={"100%"} iconSearch={"Home"} iconSelection={Ga4XD6inS} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"ALlAvyiu4"} layoutId={"ALlAvyiu4"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"blPTmyqqg-hover": {color: "rgb(0, 0, 0)"}, "GgjuZoKLV-hover": {color: "var(--token-08b0318b-77e7-4784-bf4f-81a37b1f9824, rgb(39, 7, 245))"}, GgjuZoKLV: {color: "rgb(255, 255, 255)"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OvI9X.framer-wiff9z, .framer-OvI9X .framer-wiff9z { display: block; }", ".framer-OvI9X.framer-hpqhys { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-OvI9X .framer-o0pp9g-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"GgjuZoKLV":{"layout":["fixed","fixed"]},"eU3sV1ZVl":{"layout":["fixed","fixed"]},"spalUaWay":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Ga4XD6inS":"icon","n98VOgYIP":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGEAw_rOIs: React.ComponentType<Props> = withCSS(Component, css, "framer-OvI9X") as typeof Component;
export default FramerGEAw_rOIs;

FramerGEAw_rOIs.displayName = "Social";

FramerGEAw_rOIs.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerGEAw_rOIs, {variant: {options: ["blPTmyqqg", "GgjuZoKLV"], optionTitles: ["Variant 1", "light"], title: "Variant", type: ControlType.Enum}, Ga4XD6inS: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Twitter", description: undefined, hidden: undefined, title: "Icon"}, n98VOgYIP: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerGEAw_rOIs, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})